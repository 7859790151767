.plans-container {
    width: 100%;
    margin-top: 1%;
    min-height: 18vh;
}
.plans-container .plan-create-button {
    margin-top: 3%;
}
.plans-container > h2 {
    color: #336699;
    text-align: left;
    width: 80%;
    margin: auto;
    margin-top: 1%;
    border-bottom: 2px solid rgba(85, 82, 82, 0.1);
}
.plan-section {
    margin: auto;
    width: 80%;
    margin-top: 1%;
    min-height: 44vh;
}
.plan-section .plans-card {
    width: 32%;
    min-width: 400px;
    height: 380px;
    background-color: #ffffff;
    border: solid 1px #ffffff;
    border-radius: 10px;
    margin-top: 1.5%;
    color: #336699;
    display: inline-grid;
    grid-template-areas:
    "name name price"
    "desc desc desc"
    "button button button";
    grid-gap: 30px;
    grid-template-rows:  35% 30% 25%;
    grid-template-columns: 1fr 1fr 1fr;
    margin-left: 1.5%;
}
.plan-section .plans-card .deleteButton {
    grid-area: name;
    width: 20px;
    height: 20px;
    border: 2px solid #336699;
    border-radius: 100px;
    margin-left: 5%;
    margin-top: 5%;
    cursor: pointer;
    font-weight: bolder;
}
.plan-section .plans-card .deleteButton:hover {
    transform:scale(1.05,1.05);
    -webkit-transform:scale(1.05,1.05);
    -moz-transform:scale(1.05,1.05);
}
.plan-section .plans-card:hover {
    border: solid 1px #336699;
}
.plans-card .plan-name {
    color: #336699;
    grid-area: name;
    display: block;
    text-align: left;
    margin-top: 15%;
    margin-left: 12%;
}
.plans-card .plan-name > h2{
    color: #336699;
    font-size: 2rem;
    word-spacing: -0.8rem;
}
.plans-card .plan-name > h3{
    color: #070740;
    word-spacing: -0.4rem;
}
.plans-card .plan-price {
    color: #336699;
    grid-area: price;
    display: flex;
    align-items: top;
    justify-content: right;
    margin-right: 28%;
    margin-top: 35%;
}
.plans-card .plan-price .dollar {
    font-size: 1.5rem;
}

.plans-card .plan-price > h2 {
    font-family: 'Rounded Elegance';
    font-size: 4rem;
    font-weight: 700;
    color: #070740;
}
.plan-description {
    grid-area: desc;
    font-size: 0.9rem;
    display: flex;
    align-items: center;
    padding: 10px 8%;
    overflow-y: scroll;
    -ms-overflow-style: none; 
    scrollbar-width: none;
}
.plan-description::-webkit-scrollbar {
    display: none;
}
.plans-card .button {
    grid-area: button;
}
.plans-card .button button {
    border: none; 
    cursor: pointer;
    color: white;
    font-weight: bold;
    width: 50%;
    padding: 12px 35px;
    border-radius: 20px;
    font-size: 16px;
    background: #336699;
    background: -webkit-linear-gradient(legacy-direction(to right), rgba(51,102,153,1) 35%, rgba(0,212,255,1) 100%);
    background: -webkit-linear-gradient(left, rgba(51,102,153,1) 35%, rgba(0,212,255,1) 100%);
    background: -o-linear-gradient(left, rgba(51,102,153,1) 35%, rgba(0,212,255,1) 100%);
    background: linear-gradient(90deg, rgba(51,102,153,1) 35%, rgba(0,212,255,1) 100%);
}
.plans-card .button > p {
    color: #336699;
    font-size: 0.9rem;
}
.plans-card .button > button:hover {
    transform:scale(1.05,1.05);
    -webkit-transform:scale(1.05,1.05);
    -moz-transform:scale(1.05,1.05);
}
.plan-section .plans-create-card {
    width: 31%;
    min-width: 400px;
    height: 500px;
    background-color: #ffffff;
    border-radius: 15px;
    margin-left: 1.5%;
    margin-top: 1.5%;
    color: #336699;
    display: inline-grid;
    grid-template-areas:
    "header card-header step"
    "info info info"
    "content content content"
    "button button button";
    grid-template-rows:  15% 5% 60% 20%;
    grid-template-columns: 33.3% 33.3% 33.3%;
}
.plan-section .plans-create-card .title {
    color: #336699;
    grid-area: card-header;
    display: flex;
    justify-content: left;
    align-items: center;
    font-size: 1.8rem;
    margin-left: 5%;
}
.plans-create-card .step {
    color: #336699;
    grid-area: step;
    display: flex;
    align-items: center;
    justify-content: right;
    font-size: 2rem;
    margin-right: 8%;
}
.plans-create-card .text {
    color: #336699;
    grid-area: info;
    display: block;
    justify-content: center;
    font-size: 0.8rem;

}
.plans-create-card  p {
    color: #336699;
    font-size: 0.8rem;
    text-align: left;
    margin-left: 5%;
}
.plans-create-card  input {
    margin-top: 1%;
    margin-bottom: 3%;
    width: 80%;
    height: 10%;
    margin-left: 50%;
    transform: translateX(-50%);
    border: none;
    background-color: #efeff2;
    text-align: center;
    color: #336699;
    border-bottom: 2px solid #00ffff;
}
.plans-create-card .desc-input {
    margin-bottom: 4%;
    width: 80%;
    margin-top: 3%;
    margin-left: 50%;
    transform: translateX(-50%);
    height: 40%;
    text-align: left;
    resize: none;
    background-color: #efeff2;
    border: none;
    border-bottom: 2px solid #00ffff;
}
.plans-create-card .checkbox-input {
    margin-top: 3%;
    margin-bottom: 3%;
    width: 20px;
    height: 20px;
}
.plans-create-card .create-card-content {
    grid-area: content;
    display: flex;
    flex-direction: column;
    justify-content: left;
    margin-top: 5%;
    width: 100%;
}
.plans-create-card .create-card-button {
    grid-area: button;
    display: block;
    justify-content: center;
}
.noplans > p {
    padding: 5% 20%;
    color: #070740;
}