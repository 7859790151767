.button-container {
    display: inline-block;
    vertical-align: top;
    -webkit-box-shadow: 0px 0px 20px 3px rgba(39,39,131,0.2); 
    box-shadow: 0px 0px 20px 3px rgba(39,39,131,0.2);
}

.button {
    display: table-cell;
    vertical-align: middle;
    text-align: center;
    cursor: pointer;
}
.button:hover {
    transform:scale(1.05,1.05);
    -webkit-transform:scale(1.05,1.05);
    -moz-transform:scale(1.05,1.05);
}