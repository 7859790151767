.guildCustomerBar {
    width: 100%;
}
.customerBar {
    width: 80%;
    position: relative;
    text-align: center;
    margin: auto;
    background-color: #ffffff;
    border-radius: 8px;
    display: flex;
    min-height: 70px;
}
.customerBar .buttons {
    width: 30%;
    display: flex;
    justify-content: center;
    align-items: center;

}
.customerBar .text {
    width: 40%;
    display: flex;
    justify-content: center;
    align-items: center;

}
.customerBar .text p {
    color: #336699;
    font-size: 0.9rem;
    font-family: 'roboto_mono', 'Roboto', 'Oxygen', 'Droid Sans', 'Helvetica Neue', sans-serif;
}
.customerBar .inputs {
    width: 30%;
    display: flex;
    justify-content: center;
    align-items: center;

}
.customerBar .inputs  > input {
    text-align: center;
    display: inline-block;
    margin: 0;
    padding: 0;
    height: 30px;
    margin-right: 5%;
    margin-top: 1%;
    font-size: 1rem;
    background-color: #f8f8f9;
    border: none;
    outline-width: 0;
    color: #336699;
    border-bottom: 3px solid #00ffff;
}
.customerBar .inputs  > input::placeholder {
    color: #336699;
}
.customerBar .inputs  > input:-ms-input-placeholder {
    color: #336699;
}
.customerBar .inputs  > input::-webkit-input-placeholder {
    color: #336699;
}