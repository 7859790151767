.guildDetails-container {
    width: 100%;
    margin-bottom: 1%;
}
.guildDetails-guild {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
    margin-top: 1%;
    width: 80%;
    height: 180px;
    background-color: #ffffff;
    border-radius: 8px;
}
.guildDetails-guild .rightContent h2 {
    text-align: left;
    display: block;
    color:#336699;
    margin-top: 0.5%    ;
}
.guildDetails-guild .rightContent p {
    text-align: left;
    margin-top: 10px;
    font-size: 0.8rem;
    color: #336699;
}
.guildDetails-guild .rightContent .admin {
    text-align: left;
    margin-top: 10px;
    color: red;
}