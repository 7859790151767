.loading {
    height: 100vh;
    width: 100vw;
    background-color: #07073d;
    display: flex;
    position: relative;
    justify-content: center;
    align-items: center;
}
.loading .loadingFix {
    width: 20%;
    height: auto;
    z-index: 1000;
}
.loading .loadingSpin {
    width: 20%;
    height: auto;
    position: absolute;
    z-index: 1;
}