.background {
    width: 100%;
    min-height: 100%;
    text-align: center ;
    border-top: 1px solid;
}
.background-create {
    width: 100%;
    text-align: center ;
    border-top: 1px solid;
    background-color: #07073d;
}