.GuildStats-wrapper {
    margin-top: 3%;
    margin-bottom: 3%;
    display: flex;
    flex-direction: column;
    color: #336699;
}
.GuildStats-wrapper  p {
    color: #336699;
}
.GuildStats-wrapper li {
    color: #336699;
}
.stats-header {
    width: 80%;
    height: 100px;
    position: relative;
    margin: auto;
    display: flex;
}
.stats-header .members {
    width: 24%;
    background-color: #ffffff;
    margin-right: 1.33%;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
}
.stats-header h2 {
    color: #336699;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    font-size: 2.2rem;
}
.stats-header h3 {
    color: #336699;
    text-align: right;
    margin-right: 5%;
}
.stats-header .plan {
    width: 24%;
    background-color: #ffffff;
    margin-right: 1.33%;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.stats-header .event {
    width: 24%;
    background-color: #ffffff;
    margin-right: 1.33%;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.stats-header .earned {
    width: 24%;
    background-color: #ffffff;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.GuildStats-wrapper .table {
    width: 80%;
    height: 50px;
    position: relative;
    margin: auto;
    display: flex;
    justify-content: space-around;
    margin-top: 1%;
    
}
.GuildStats-wrapper .table p {
    height: 100%;
    border-left: solid 4px #efeff2;
    background-color: #ffffff;
    width: 7.69%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 0.8vw;
}
.GuildStats-wrapper .tableRow {
    width: 80%;
    position: relative;
    margin: auto;
    display: flex;
}
.GuildStats-wrapper .tableRow p {
    font-size: 0.8vw;
    width: 7.69%;
    height: 40px;
    background-color: #ffffff;
    border-top: solid 4px #efeff2;
    border-left: solid 4px #efeff2;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
}
.GuildStats-wrapper .tableRow .numbers {
    font-size: 0.9vw;
}