.navbar {
    position: relative;
    z-index: 100;
    width: 100%;
    display: flex;
    justify-content: end;
}
.navbar .button button {
    border: none; 
    cursor: pointer;
    color: white;
    font-weight: bold;
    border-radius: 18px;
    background: rgb(0,212,255);
    background: linear-gradient(90deg, rgba(0,212,255,1) -100%, rgba(51,102,153,1) 50%, rgba(0,212,255,1) 110%);
}