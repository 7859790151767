.events {
    width: 100%;
    text-align: center ;
    margin-bottom: 5%;
    min-height: 18vh;
}

.events > h2 {
    margin-top: 1%;
    color: #336699;
    text-align: left;
    width: 80%;
    margin: auto;
    border-bottom: 2px solid rgba(85, 82, 82, 0.1);
}

.events .events-wrapper {
    width: 100%;
    margin: auto;
    margin-top: 1%;
}
.events .events-wrapper .events-card {
    width: 39%;
    min-width: 400px;
    height: 400px;
    background-color: #ffffff;
    border: solid 0.5px #ffffff;
    border-radius: 15px;
    margin-left: 0.5%;
    margin-right: 0.5%;
    margin-top: 1.5%;
    color: #336699;
    display: inline-grid;
    grid-template-areas:
    "name name price"
    "desc desc desc"
    "button button button";
    grid-gap: 30px;
    grid-template-rows:  35% 30% 25%;
    grid-template-columns: 1fr 1fr 1fr;
}
.events .events-wrapper .events-card:hover {
    border: solid 1px #336699;
}
.events .events-wrapper .events-card .deleteButton {
    grid-area: name;
    width: 20px;
    height: 20px;
    border: 2px solid #336699;
    border-radius: 100px;
    margin-left: 5%;
    margin-top: 5%;
    cursor: pointer;
    font-weight: bolder;
}
.events .events-wrapper .events-card .deleteButton:hover {
    transform:scale(1.05,1.05);
    -webkit-transform:scale(1.05,1.05);
    -moz-transform:scale(1.05,1.05);
}
.events-card .event-name {
    color: #336699;
    grid-area: name;
    display: block;
    text-align: left;
    margin-top: 10%;
    margin-left: 12%;
}
.events-card .event-name > h2{
    color: #336699;
    font-size: 2rem;
    word-spacing: -0.8rem;
    margin-bottom: 2%;
}
.events-card .event-name > h3{
    display: inline-block;
    vertical-align: top;
    height: 20px;
    font-size: 0.8rem;
    color: #ffffff;
    background-color: #336699;
    padding: 2px 10px;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
}
.events-card .event-name > h4{
    display: inline-block;
    vertical-align: top;
    height: 18px;
    font-size: 0.8rem;
    color: #000000;
    background-color: #ffffff;
    padding: 2px 10px;
    border: 1px solid #336699;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
}
.events-card .event-price {
    color: #336699;
    grid-area: price;
    display: flex;
    align-items: top;
    justify-content: right;
    margin-right: 30%;
    margin-top: 15%;
}
.events-card .event-price .dollar {
    font-size: 2rem;
    margin-top: 17%;
}
.events-card .event-price > h2 {
    margin-top: 15%;
    font-size: 4rem;
    font-weight: 700;
    font-family: 'Rounded Elegance';
    color: #070740;
}
.event-duration {
    grid-area: duration;
    margin-bottom: 2%;
    font-weight: bold; 
}
.event-description {
    grid-area: desc;
    font-size: 0.8rem;
    margin: 0% 10%;
    color: #336699;
    text-align: center;
    overflow-y: scroll;
    -ms-overflow-style: none; 
    scrollbar-width: none;
}
.event-description::-webkit-scrollbar {
    display: none;
}
.events-card .button {
    grid-area: button;
}
.events-card .button button {
    border: none; 
    cursor: pointer;
    color: white;
    font-weight: bold;
    width: 50%;
    padding: 12px 35px;
    border-radius: 20px;
    font-size: 16px;
    background: #336699;
    background: -webkit-linear-gradient(legacy-direction(to right), rgba(51,102,153,1) 35%, rgba(0,212,255,1) 100%);
    background: -webkit-linear-gradient(left, rgba(51,102,153,1) 35%, rgba(0,212,255,1) 100%);
    background: -o-linear-gradient(left, rgba(51,102,153,1) 35%, rgba(0,212,255,1) 100%);
    background: linear-gradient(90deg, rgba(51,102,153,1) 35%, rgba(0,212,255,1) 100%);
}
.events-card .button > p {
    color: #336699;
    font-size: 0.9rem;
}
.events-card .button > button:hover {
    transform:scale(1.05,1.05);
    -webkit-transform:scale(1.05,1.05);
    -moz-transform:scale(1.05,1.05);
}
.event-create-button {
    margin-top: 5%;
}
.events .events-wrapper .create-events-card {
    width: 43.5%;
    min-width: 400px;
    height: 400px;
    background-color: #ffffff;
    border: solid 0.5px #00ffff;
    border-radius: 15px;
    margin-left: 1.5%;
    margin-top: 1.5%;
    color: #336699;
    display: inline-grid;
    grid-template-areas:
    "title title"
    "input-left input-right"
    "desc desc"
    "btn btn";
    grid-gap: 10px;
    grid-template-rows:  10% 40% 30% 20%;
    grid-template-columns: 50%;
}
.events .events-wrapper .create-events-card > h3 {
    grid-area: title;
    color: #336699;
    font-size: 1.5rem;
    width: 100%;
}
.events .events-wrapper .create-events-card .event-leftContent {
    margin-left: 3%;
    grid-area: input-left;
    width: 100%;
    height: 100%;
    font-size: 0.8rem;
    text-align: left;
    padding-right: 2%;
    border-right: solid 1px #336699;
}
.events .events-wrapper .create-events-card .event-leftContent > input {
    border: none;
    background-color: #efeff2;
    text-align: center;
    color: #336699;
    border-bottom: 2px solid #00ffff;
    margin-top: 2%;
    width: 100%;
}
.events .events-wrapper .create-events-card .event-rightContent > input {
    border: none;
    background-color: #efeff2;
    text-align: center;
    color: #336699;
    border-bottom: 2px solid #00ffff;
    margin-top: 2%;
    width: 100%;
}
.events .events-wrapper .create-events-card .event-rightContent .price {
    margin-top: 5%;
}
.events .events-wrapper .create-events-card .event-leftContent > p {
    color: #336699;
}
.events .events-wrapper .create-events-card .event-rightContent { 
    margin-left: 5%;
    grid-area: input-right;
    text-align: left;
    width: 92%;
    height: 100%;
    font-size: 0.8rem;
}
.events .events-wrapper .create-events-card .event-rightContent > p {
    color: #336699;
}
.events .events-wrapper .create-events-card .event-description {
    grid-area: desc;
    width: 97%;
    height: 100%;
    text-align: left;
    margin-left: 1.5%;
}
.events .events-wrapper .create-events-card .event-description > p {
    color: #336699;
}
.events .events-wrapper .create-events-card .event-description > textarea {
    border: none;
    background-color: #efeff2;
    text-align: center;
    color: #336699;
    border-bottom: 2px solid #00ffff;
    resize: none;
    width: 100%;
    height: 80%;
}
.events .events-wrapper .create-events-card .create-card-button {
    grid-area: btn;
    width: 100%;
    height: 100%;
}
.events .events-wrapper .create-events-card .event-rightContent > .inputRight {
    display: inline-block;
    vertical-align: top;
    width: 50%;
    cursor: pointer;
}
.events .events-wrapper .create-events-card .event-rightContent > .inputLeft {
    display: inline-block;
    vertical-align: top;
    width: 50%;
    cursor: pointer;
}
.noevents > p {
    padding: 5% 25%;
    color: #070740;
}