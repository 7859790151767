.bulletpoints-container {
    padding: 3% 5% 3% 5%;
    width: 100%;
    background-color: #ffffff;
    border-top: 1px solid;
    border-image: linear-gradient(to right, #e84142, red) 0.5;
}
.bulletpoints-title {
    position: relative;
    left: -5%;
    width: 100%;
    text-align: center;
    color:#336699;
}

.bulletpoints-title h2 {
    display: inline-block;
    vertical-align: top;
    color:#336699;
}

.bulletpoints-title img {
    display: inline-block;
    vertical-align: top;
    margin-right: 10px;
    margin-top: 5px;
}

.bulletpoints-title p {
    margin-top: 10px;
    color:#336699;
}

.bulletpoints {
    margin-top: 3%;
}

.bulletpoint {
    vertical-align: top;
    margin-right: 4%;
}

.bulletpoint:last-child {
    margin-right: 0%;
}

.bulletpoint h3 {
    display: inline-block;
    vertical-align: top;
    color: #336699;
}

.bulletpoint img {
    display: inline-block;
    vertical-align: top;
    margin-right: 10px;
    margin-top: 5px;
}

.bulletpoint p {
    margin-top: 5px;
    font-family: 'roboto_mono', 'Roboto', 'Oxygen', 'Droid Sans', 'Helvetica Neue', sans-serif;
    color:#336699;
}