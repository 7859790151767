.createGuild {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 0;
    margin: 0;
    background-color: #07073d;
}
.createGuild .image-viewer {
    position: absolute;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    max-width: 80vw;
    max-height: 80vh;
}
.createGuild .image-viewer button {
    position: absolute;
    padding: 10px 20px;
    background-color: green;
    cursor: pointer;
    border: none;
}
.createGuild .image-viewer button:hover {
    background-color: red;
    transform: scale(1.1);
}
.leftRow.leftRow {
    display: inline-block;
    width: 48%;
    height: 100%;
    vertical-align: top;
}
.subRightRow {
    display: inline-block;
    width: 70%;
    height: 100%;
    vertical-align: top;
}

.subRightRow > p {
    margin: 0;
    padding: 1% 0;
    font-size: 0.8rem;
    font-family: 'roboto_mono', 'Roboto', 'Oxygen', 'Droid Sans', 'Helvetica Neue', sans-serif;
}
.subLeftRow {
    display: inline-block;
    width: 30%;
    height: 100%;
    vertical-align: top;
}

.leftRow .content-area {
    margin-top: 25%;
    margin-left: 10%;
    margin-right: 10%;
    text-align: left;
}

.leftRow .buttons-area {
    text-align: center;
    margin-top: 10%;
    width: 100%;
}

.leftRow .content-area > h1 {
    margin: 0;
    padding: 0;
    line-height: 140%;
}

.leftRow .content-area > p {
    margin: 0;
    padding: 0;
    font-size: 0.8rem;
    font-family: 'roboto_mono', 'Roboto', 'Oxygen', 'Droid Sans', 'Helvetica Neue', sans-serif;
}
.leftRow .content-area .helper:hover {
    text-decoration: underline;
    color:#00FFFF;
    cursor: pointer;
}
.leftRow .content-area > input {
    display: inline-block;
    margin: 0;
    padding: 0;
    height: 30px;
    width: 100%;
    margin-top: 3%;
    font-size: 1.2rem;
    background: transparent;
    font-size: 1.2rem; 
    border: none;
    outline-width: 0;
    color: #00FFFF;
    border-bottom: 3px solid #ffffff;
}
.leftRow .content-area > textarea {
    display: inline-block;
    height: 100px;
    width: 100%;
    margin-top: 3%;
    font-size: 1.2rem;
    background: transparent;
    border: none;
    outline-width: 0;
    color: #00FFFF;
    border: 1px solid #ffffff10;
    border-bottom: 3px solid #ffffff;
    resize: none;
}
.leftRow .content-area > textarea::placeholder {
    color: #ffffff;
    font-weight: bold;
}
.leftRow .content-area .subLeftRow > select {
    display: inline-block;
    margin: 0;
    padding: 0;
    height: 30px;
    font-size: 1rem;
    color: #ffffff;
    background-color: #07073d;
    border: 2px solid #ffffff;
}
::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #ffffff;
    opacity: 1; /* Firefox */
}

:-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: #ffffff;
}

::-ms-input-placeholder { /* Microsoft Edge */
    color: #ffffff;
}

.continentText {
    display: inline-block;
    vertical-align: top;
}

.rightRow {
    display: inline-block;
    width: 48%;
    height: 100%;
    vertical-align: top;
}

.rightRow .logo-area {
    margin: 20%;
    text-align: left;
}

.rightRow .logo-area > .logo-img {
    width: 100%;
    height: 100%;
}

.rightRow .logo-area > p {
    font-family: 'roboto_mono', 'Roboto', 'Oxygen', 'Droid Sans', 'Helvetica Neue', sans-serif;
    margin-bottom: 1%;
}
.rightRow .logo-area p > a:hover {
    text-decoration: underline;
    color:#00FFFF;
}

.rightRow .logo-area > h2 {
    color: #00ffff;
    margin-bottom: 3%;
}
.rightRow .logo-area > a {
    font-size: 0.8rem;
    text-decoration: underline;
    cursor: pointer;
}
.rightRow .logo-area > a:hover {
    font-size: 0.8rem;
    color: #00FFFF;
    text-decoration: underline;
}
.rightRow .logo-area > img {
    margin-bottom: 3%;
    max-width:  400px;
    max-height: 400px;
    object-fit: contain;
    cursor: pointer;
    margin-left: 5%
}