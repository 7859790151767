.howtouse-container {
    width: 100%;
    margin-top: 3%;
    margin-bottom: -1%;
}
.howtouse {
    width: 60%;
    position: relative;
    text-align: center;
    margin: auto;
    padding: 1% 0%;
    background-color: orange;
    border-radius: 4px;
    display: flex;
}
.howtouse .left-part {
    width: 10%;
}
.howtouse .left-part img {
    width: 50%;
    display: flex;
    margin-left: 20%;
    justify-content: left;
}
.howtouse .right-part {
    width: 85%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}
.howtouse .right-part h2 {
    color: black;
}
.howtouse .right-part li {
    color: black;
    display: flex;
    text-align: left;
    margin-top: 2%;
}