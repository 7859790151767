.footer {
    position: relative;
    width: 100%;
    background-color: #ffffff;
    bottom: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 1.5%;
    margin-top: 5%;
}
.footer .footernav {
    display: flex;
    width: 80%;
    justify-content: center;
    flex-wrap: wrap;
}
.footer .footernav .navlist-item {
    width: 16%;
    min-width: 280px;
    text-align: center;
    color: black;
    font-size: 0.8rem;
    color: #336699;
}
.footer .footernav .navlist-item:hover {
    text-decoration: underline;
}
.footer .footernav p:hover {
    cursor: pointer;
    text-decoration: underline;
}
.footer .copyright p {
    margin-top: 5%;
    font-size: 0.7rem;
    color: #336699;
}
.modal-guildManager-wrapper {
    position: fixed; 
    z-index: 1; 
    left: 0;
    top: 0;
    width: 100%;    
    height: 100%; 
    overflow: hidden; 
    background-color: rgba(115, 115, 115, 0.3);
}
.modal-guildManager {
    background-color: #ffffff;
    border-radius: 7px;
    margin: 15% auto;
    width: 40%; 
    height: 40%;
}
.modal-guildManager .header-guildManager {
    height: 20%;
    display: flex;
    flex-direction: column;
}
.modal-guildManager .header-guildManager > h2 {
    font-size: 0.7rem;
    color: #336699;
    margin: auto;
}
.modal-guildManager .header-guildManager .close-button {
    display: flex;
    text-align: left;
    color: #336699;
    margin-top: 1%;
    margin-left: 1%;
    cursor: pointer;
}
.modal-guildManager .content-guildManager {
    height: 80%;
    width: 70%;
    margin: auto;
    overflow-x: hidden;
    overflow-y: scroll;
    -ms-overflow-style: none; 
    scrollbar-width: none;
    display: flex;
    align-items: center;
}
.modal-guildManager .content-guildManager .guildManager-button-list {
    margin-left: 7%;
}
.modal-guildManager .content-guildManager::-webkit-scrollbar {
    display: none;
}
.modal-guildManager .content-guildManager button {
    width: 45%;
    height: 40px;
    margin-top: 1%;
    background-color:#6293c4;
    border: solid 2px #ffffff;
    color: #ffffff;
}
.modal-guildManager .content-guildManager button:hover {
    transform: scale(1.05);
    cursor: pointer;
}