.stories {
    width: 100%;
    text-align: center ;
    background-color: #f8f8f9;   
}
.stories .stories-container {
    padding-left: 20%;
    padding-right: 20%;
}

.story {
    margin-top: 2%;
    width: 100%;
}
.stories .story > .storyline {
    vertical-align: middle;
}

.stories .story .storyline > h4 {
    color:#336699;
    font-size: 1.2rem;
}

.stories .story .storyline > p {
    padding-top: 10px;
    font-family: 'roboto_mono', 'Roboto', 'Oxygen', 'Droid Sans', 'Helvetica Neue', sans-serif;
    color:#336699;
}

.stories .story > .storyline-picture {
    vertical-align: middle;
    height: 200px;
    overflow: hidden;
}
.stories .story .storyline-picture > img {
    display: inline-block;
    border-radius: 8px;
    vertical-align: middle;
    max-width:100%;
    max-height:100%;
}
.stories .story .storyline-picture > img:hover {
    transform: scale(1.01);
    border-radius: 8px;
}