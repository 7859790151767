.faq-wrapper {
    background-color: #ffffff;
    margin-top: 2%;
    padding-top: 1%;
    padding-bottom: 5%;
}
.faq {
    margin: auto;
    margin-top: 3%;
}
.faq h1 {
    font-family: 'roboto_mono', 'Roboto', 'Oxygen', 'Droid Sans', 'Helvetica Neue', sans-serif;
    color:#336699;
    text-align: center;
    margin-bottom: 4%;
}
.faq .question {
    width: 100%;
    display: inline-flex;
    margin-top: 1%;
    background-color: rgba(51,102,153,0.9);
}
.faq .question:hover {
    cursor: pointer;
}
.faq .question h2 {   
    width: 98%;
    color:#ffffff;
    margin-left: 5%;
    display: flex;
    align-items: center;
}
.faq .question p {
    width: 2%;
    color:#ffffff;
    font-size: 2.2rem;
    display: flex;
    justify-content: center;
    margin-right: 5%;
    align-items: center;
}
.faq .answer {
    background-color: rgba(51,102,153,0.9);
    margin-top: 0.5%;
}
.faq .answer:hover {
    cursor: pointer;
}
.faq .answer > p {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 4%;
    white-space: pre-line;
}