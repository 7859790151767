.header {
    width: 100vw;
    height: 100vh;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    vertical-align: middle;
    background: url('../../assets/images/background.jpg');
    background-repeat: no-repeat;
    background-position: center;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
}
.header .home-leftRow {
    height: 100%;
    align-items: center;
    justify-content: center;
}

.header .home-leftRow .home-content-area {
    margin-left: 10%;
    margin-right: 10%;   
}

.header .home-leftRow .home-buttons-area {
    text-align: left;
    margin-top: 5%;
    width: 100%;
}

.header .home-leftRow .home-content-area > h1 {
    margin: 0;
    padding: 0;
    line-height: 140%;
}

.header .home-leftRow .home-content-area > .home-powered {
    margin-top: 15%;
}
.header .home-leftRow .home-content-area .home-powered > .avax {
    display: inline-block;
    vertical-align: top;
}

.header .home-leftRow .home-content-area .home-powered > .powered-text {
    font-size: 0.8rem;
    margin-top: 6px;
    margin-left: 10px;
    display: inline-block;
    vertical-align: top;
}

.header .home-leftRow .home-content-area > .home-socials {
    margin-top: 3%;
}
.header .home-leftRow .home-content-area .home-socials > .discord {
    display: inline-block;
    vertical-align: top;
    margin-right: 10px;
}
.header .home-leftRow .home-content-area .home-socials > .twitter {
    display: inline-block;
    vertical-align: top;
}

.header .home-leftRow .home-content-area .home-socials > .socials-text {
    font-size: 0.8rem;
    margin-top: 5px;
    margin-right: 10px;
    color: #a5a5b0;
    display: inline-block;
    vertical-align: top;
}

.header .home-rightRow {
    height: 100%;
    align-items: center;
    justify-content: center;
    vertical-align: middle;
}
.header .home-rightRow .home-logo-area {
    height: 55%;
    width: 100%;
    text-align: center;
}

.header .home-rightRow .home-logo-area > .home-logo-img {
    height: 100%;
    max-width: 100%;
    object-fit: contain;
}
