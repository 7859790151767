.tos {
    width: 100%;
    height: 100%;
    margin: auto;
}
.tos .container {
    margin: auto;
    width: 80%;
    height: 100%;
}
.tos .container .tos-title {
    width: 100%;
    margin-top: 3%;
    display: flex;
    justify-content: center;
    color: #336699;
    font-family: "Poppins",sans-serif;
}
.tos .container .subtitle {
    width: 100%;
    margin-top: 2%;
    display: flex;
    justify-content: center;
    color: #336699;
    font-family: "Poppins",sans-serif;
}
.tos .container ul {
    margin: auto;
    width: 90%;
}
.tos .container ul li {
    margin-top: 2%;
}
.tos .container ul li h3 {
    color: #336699;
    font-family: "Poppins",sans-serif;
    text-decoration: underline;
    margin-bottom: 1%;
}
.tos .container ul li p {
    color: #336699;
    padding: 20px;
    border-radius: 8px;
    font-family: "Poppins",sans-serif;
    background-color: #ffffff;
}