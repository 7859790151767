@font-face {
  font-family: 'roboto_mono';
  src: url('./assets/fonts/roboto_mono_for_powerline-webfont.woff') format('woff2'),
       url('./assets/fonts/roboto_mono_for_powerline-webfont.woff2') format('woff');
  font-weight: normal;
  font-style: normal;

}

@font-face {
  font-family: 'roboto_mono_bold';
  src: url('./assets/fonts/roboto_mono_bold_for_powerline-webfont.woff2') format('woff2'),
       url('./assets/fonts/roboto_mono_bold_for_powerline-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Rounded Elegance';
  src: url('./assets/fonts/RoundedElegance-Regular.woff2') format('woff2'),
      url('./assets/fonts/RoundedElegance-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

body {
  margin: 0;
  width: 100%;
  min-height: 100%;
  background-color: #f8f8f9;;
  color: white;
  font-family: 'roboto_mono', 'Roboto', 'Oxygen', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -ms-overflow-style: none; 
  scrollbar-width: none;
}
body::-webkit-scrollbar {
  display: none;
}

input, ul, li {
  color: white;
  font-family: 'roboto_mono', 'Roboto', 'Oxygen', 'Droid Sans', 'Helvetica Neue', sans-serif;
}
h1, h2, h3, h4, h5, h6,  span, input, a, p {
  padding: 0;
  margin: 0;
  color: #ffffff;
  font-family: 'roboto_mono_bold', 'Roboto', 'Oxygen', 'Droid Sans', 'Helvetica Neue', sans-serif;
}

a {
  text-decoration: none;
}